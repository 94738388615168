<template>
  <div class="desc">
    <div class="name">{{ t('introduction.title') }}</div>
    <div class="detail">{{ t('introduction.content') }}</div>
  </div>
</template>

<script setup>
import {useI18n} from "vue-i18n";

const {t} = useI18n();

</script>

<style lang="less" scoped>
.desc {
  color: #fff;
  font-size: 20px;

  .name {
    font-weight: bold;
  }

  .detail {
    font-size: 14px;
    padding: 5px 10px;
    text-align: left;
  }
}

</style>
