export function formatNumber(value) {
    if (value === undefined || value === null) {
        return '0.00';
    }
    const num = parseFloat(value.toFixed(2)); // 四舍五入到两位小数
    if (num === 0) {
        return '0.00'
    }
    const parts = num.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); // 添加逗号分隔符
    return parts.join(".");
}
