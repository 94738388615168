import {createApp} from 'vue'
import {
    NavBar, Icon, Field, CellGroup, Dialog, Image, Swipe,
    SwipeItem, NoticeBar, DropdownMenu, DropdownItem, Cell, Popup,
    CountDown, Progress, Popover, Divider, Button, Empty
} from 'vant';
import 'vant/lib/index.css'
import App from './App.vue'
import router from './router'
import {createI18n} from "vue-i18n";
import store from "@/utils/store";

import cn from "./assets/locales/cn";
import en from "./assets/locales/en";

const language = {
    en: en,
    cn: cn
}

const i18n = createI18n({
    legacy: false,
    locale: "en",
    messages: language
});

const app = createApp(App)
app.use(router)
    .use(NavBar)
    .use(Icon)
    .use(Field)
    .use(CellGroup)
    .use(Dialog)
    .use(Image)
    .use(Swipe)
    .use(SwipeItem)
    .use(NoticeBar)
    .use(DropdownMenu)
    .use(DropdownItem)
    .use(Cell)
    .use(Popup)
    .use(CountDown)
    .use(Progress)
    .use(Popover)
    .use(Divider)
    .use(Button)
    .use(store)
    .use(Empty)
    .use(i18n);
app.mount('#app')
