import {createRouter, createWebHashHistory} from 'vue-router';
import Invest from '../pages/Invest';
import Profit from '../pages/Profit';
import WhitePage from "@/pages/WhitePage.vue";

const routes = [
    {
        path: '/',
        redirect: '/index'
    },
    {
        name: 'Invest',
        path: '/index',
        component: Invest
    },
    {
        name: 'Profit',
        path: '/profit',
        component: Profit
    },
    {
        name: 'WhitePage',
        path: '/white-page',
        component: WhitePage
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router;
