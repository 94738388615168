<template>
  <div class="profit">
    <div class="nav">
      <nav-bar :wallet-address="walletAddress" :is-home="false"/>
    </div>
    <van-empty v-if="!hasOrder" image="search" :description="t('order.empty')"/>
    <div class="main-container" v-else>
      <div class="order-box">
        <span class="title">{{ t('order.title') }}</span>
        <van-divider/>
        <div class="item-box">
          <span>{{ t('order.investmentAmount') }}</span>
          <span>{{ formatNumber(account.investmentAmount) }} USDT</span>
        </div>
        <div class="item-box">
          <span>{{ t('order.fibonacciAmount') }}</span>
          <span>{{ formatNumber(account.fibonacciAmount) }} FBNQ</span>
        </div>
        <div class="item-box">
          <span>{{ t('order.referRewards') }}</span>
          <span>{{ formatNumber(referRewards) }} USDT</span>
        </div>
        <div class="item-box">
          <span>{{ t('order.releaseAmount') }}</span>
          <span>{{ formatNumber(account.releaseAmount) }} FBNQ</span>
        </div>
        <div class="item-box">
          <span>{{ t('order.extractedAmount') }}</span>
          <span>{{ formatNumber(account.extractedAmount) }} FBNQ</span>
        </div>
        <div class="item-box">
          <span>{{ t('order.investmentTime') }}</span>
          <span>{{ timestampToDateFormat(account.investmentTime) }}</span>
        </div>
        <div class="item-box">
          <span>{{ t('order.redemptionTime') }}</span>
          <span>{{ timestampToDateFormat(account.investmentTime + duration) }}</span>
        </div>

        <div class="withdraw-button">
          <van-button color="#000"
                      :loading="withdrawReferRewardsLoading"
                      :loading-text="t('order.extracting')"
                      plain
                      block
                      @click="withdrawReferRewards">{{ t('order.extractRewards') }}
          </van-button>
        </div>
      </div>

      <span class="remark">{{ t('order.remark') }}</span>
    </div>
  </div>

</template>

<script setup>
import NavBar from "@/components/NavBar.vue";
import WalletUtils from "@/utils/WalletUtils";
import {onMounted, ref} from "vue";
import {useStore} from "vuex";
import {formatNumber} from "@/utils/NumberFormatUtils";
import {useI18n} from 'vue-i18n';
import {showFailToast, showSuccessToast} from "vant";

const {t} = useI18n();
const store = useStore();
let web3 = null;
let contractInstance = null;
const initWeb3 = async () => {
  await store.dispatch('initWeb3AndContract');
  web3 = store.state.web3;
  contractInstance = store.state.contractInstance;
}

const account = ref({
  investmentAmount: 0,
  fibonacciAmount: 0,
  releaseAmount: 0,
  extractedAmount: 0,
  investmentTime: 0
})

const duration = ref(0);
const getOrderInfo = async () => {
  try {
    const durationRes = await contractInstance.methods.redemptionDuration().call();
    console.log(durationRes);
    duration.value = Number(durationRes);
    const accountRes = await contractInstance.methods.accounts(walletAddress.value).call();
    account.value.investmentAmount = Number(web3.utils.fromWei(accountRes.investmentAmount, 'ether'));
    account.value.fibonacciAmount = Number(web3.utils.fromWei(accountRes.fibonacciAmount, 'ether'));
    account.value.releaseAmount = Number(web3.utils.fromWei(accountRes.releaseAmount, 'ether'));
    account.value.extractedAmount = Number(web3.utils.fromWei(accountRes.extractedAmount, 'ether'));
    account.value.investmentTime = Number(accountRes.investmentTime);
    if (accountRes.exist) {
      hasOrder.value = true
    }
    console.log(account.value);
  } catch (error) {
    console.log(error);
    hasOrder.value = false;
  }
}

const referRewards = ref(0);
const getReferRewards = async () => {
  const rewards = await contractInstance.methods.referRewards(walletAddress.value).call();
  referRewards.value = Number(web3.utils.fromWei(rewards, 'ether'));
}

const withdrawReferRewardsLoading = ref(false)
const withdrawReferRewards = async () => {
  try {
    withdrawReferRewardsLoading.value = true;
    await contractInstance.methods.extractRecommendReward(web3.utils.toWei(String(referRewards.value), 'ether')).send({from: walletAddress.value});
    withdrawReferRewardsLoading.value = false;
    showSuccessToast(t('order.success'));
  } catch (error) {
    console.log(error);
    withdrawReferRewardsLoading.value = false;
    showFailToast(t('order.failed'));
  }
}

const hasOrder = ref(false)

const walletAddress = ref(undefined);
const getWalletAddress = async () => {
  const walletUtil = new WalletUtils();
  walletAddress.value = await walletUtil.getWalletAddress();
}

// 公共方法，将十位时间戳转换为 yyyy-MM-dd HH:mm:ss 格式
const timestampToDateFormat = (timestamp) => {
  const date = new Date(parseInt(timestamp) * 1000); // JavaScript的Date对象使用的是毫秒级的时间戳，所以需要乘以1000
  const year = date.getFullYear();
  const month = padZero(date.getMonth() + 1); // getMonth()返回的月份是从0开始的，所以需要+1
  const day = padZero(date.getDate());
  const hours = padZero(date.getHours());
  const minutes = padZero(date.getMinutes());
  const seconds = padZero(date.getSeconds());
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

// 辅助方法，用于在数字前面补零
function padZero(num) {
  return num.toString().padStart(2, '0');
}

onMounted(async () => {
  await getWalletAddress();
  await initWeb3();
  await getOrderInfo();
  await getReferRewards();
})
</script>

<style lang="less" scoped>
.profit {
  width: 100%;
  height: 100vh;
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .nav {
    flex: 0 0 auto;
  }

  .main-container {
    width: 100%;
    overflow: auto;
    flex: 1 0 0;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .order-box {
      width: 100%;
      padding: 20px;
      border: 1px solid gray;
      border-radius: 10px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;

      .title {
        width: 100%;
        color: white;
        font-size: 16px;
        font-weight: 800;
        display: flex;
        align-items: flex-start;
      }

      .item-box {
        width: 100%;
        padding: 5px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: white;
        font-size: 14px;
      }

      .withdraw-button {
        width: 100%;
        margin-top: 10px;
      }
    }

    .remark {
      padding: 20px 0;
      color: rgba(255, 184, 31, 0.77);
      font-size: 14px;
      display: flex;
    }
  }
}
</style>
