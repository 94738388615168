<template>
  <div class="index">
    <div class="top">
      <nav-bar :wallet-address="walletAddress" :is-home="true"/>
    </div>
    <div class="main">
      <introduction/>
      <timer :expire-time="baseInfo.expirationTime"/>
      <trade-component
          :balance="walletBalance"
          :quota-amount="baseInfo.quotaAmount"
          :investment-quantity="baseInfo.investmentAmount"
          :sale-rate="baseInfo.saleRate / 100"
          :wallet-address="walletAddress"
          :investment-percentage="baseInfo.investmentPercentage"
      />
    </div>
    <van-dialog
        v-model:show="bindDialogShow"
        title="绑定领导人"
        show-cancel-button
        @click="handleBindLeader">
      <van-cell-group inset>
        <van-field
            v-model="leaderAddress"
            rows="1"
            autosize
            type="textarea"
            placeholder="请输入领导人钱包地址"
        />
      </van-cell-group>
    </van-dialog>
  </div>
</template>

<script setup>
import NavBar from "@/components/NavBar.vue";
import Introduction from "@/components/Introduction.vue";
import Timer from '@/components/Timer.vue'
import TradeComponent from "@/components/TradeComponent.vue";
import {onMounted, ref} from "vue";
import WalletUtils from "@/utils/WalletUtils";
import {tokenAddress, contractAddress, contractABI} from "@/assets/chain/contractDetails";
import Web3 from "web3";
import {closeToast, showFailToast, showLoadingToast, showSuccessToast} from "vant";
import {useRoute} from "vue-router";

const route = useRoute();

const walletAddress = ref(undefined);

let contractInstance = undefined;
let web3 = undefined;

const initWeb3 = async () => {
  if (window.ethereum) {
    window.web3 = new Web3(window.ethereum);
    web3 = new Web3(window.ethereum);
    await window.ethereum.enable();
    contractInstance = new window.web3.eth.Contract(contractABI, contractAddress);
  } else {
    console.error("Please install MetaMask!")
  }
}

const getAddress = async () => {
  const walletUtils = new WalletUtils();
  if (walletUtils.isBlockchainBrowser()) {
    walletAddress.value = await walletUtils.getWalletAddress();
  }
}

const baseInfo = ref({
  expirationTime: 0,
  saleRate: 0,
  quotaAmount: 0,
  investmentAmount: 0,
  releaseRate: 0,
  investmentPercentage: 0
})
const getBaseInfo = async () => {
  try {
    const expirationTime = await contractInstance.methods.expirationTime().call();
    baseInfo.value.expirationTime = Number(expirationTime);
    const saleRate = await contractInstance.methods.saleRate().call();
    baseInfo.value.saleRate = Number(saleRate);
    const quotaAmount = await contractInstance.methods.quotaAmount().call();
    baseInfo.value.quotaAmount = Number(web3.utils.fromWei(quotaAmount, 'ether'));
    const investmentAmount = await contractInstance.methods.investmentAmount().call();
    baseInfo.value.investmentAmount = Number(web3.utils.fromWei(investmentAmount, 'ether'));
    const releaseRate = await contractInstance.methods.releaseRate().call();
    baseInfo.value.releaseRate = Number(releaseRate);
    baseInfo.value.investmentPercentage = baseInfo.value.investmentAmount / baseInfo.value.quotaAmount * 100
  } catch (error) {
    console.log(error)
  }
}

const walletBalance = ref(0);
const getWalletBalance = async () => {
  const walletUtils = new WalletUtils();
  if (walletUtils.isBlockchainBrowser()) {
    walletBalance.value = await walletUtils.getTokenBalance(tokenAddress, walletAddress.value);
  }
}

const bindDialogShow = ref(false)
const leaderAddress = ref(undefined)
const handleBindLeader = async () => {
  showLoadingToast('绑定中...')
  try {
    await contractInstance.methods.bindLeader(leaderAddress.value).send({from: walletAddress.value});
    closeToast();
    showSuccessToast("绑定成功");
  } catch (error) {
    closeToast();
    if (error.code === 4001) {
      showFailToast("取消绑定");
      return;
    }
    if (error.code === -32603) {
      showFailToast("绑定失败");
      return;
    }
    showFailToast("绑定失败");
  }
}

const getBindStatus = async () => {
  try {
    const res = await contractInstance.methods.referrers(walletAddress.value).call();
    return res !== '0x0000000000000000000000000000000000000000'
  } catch (error) {
    return false;
  }
}

const checkBind = async () => {
  const inviteAddress = route.query.inviteAddress;
  if (inviteAddress) {
    leaderAddress.value = inviteAddress;
    const bindStatus = await getBindStatus();
    if (!bindStatus) {
      bindDialogShow.value = true;
    }
  }
}

onMounted(async () => {
  await initWeb3();
  await getAddress();
  await getBaseInfo();
  await getWalletBalance();
  await checkBind();
})
</script>

<style lang="less" scoped>
.index {
  height: 100%;
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .top {
    flex: 0 0 auto;
  }

  .main {
    width: 100%;
    overflow: auto;
    flex: 1 0 0;
    margin-bottom: 80px;
    padding: 0 20px;
    box-sizing: border-box;
  }
}
</style>
