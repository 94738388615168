<template>
  <div class="timer">
    <div class="presaleTimer">
      <p class="timerItem" id="day">{{ times.days }}</p>
      <small class="timerDot">:</small>
      <p class="timerItem" id="hr">{{ times.hours }}</p>
      <small class="timerDot">:</small>
      <p class="timerItem" id="min">{{ times.minutes }}</p>
      <small class="timerDot">:</small>
      <p class="timerItem" id="sec">{{ times.seconds }}</p>
    </div>
  </div>
</template>

<script setup>
import {defineProps, onMounted, ref} from "vue";

const props = defineProps({
  expireTime: {
    type: Number,
    default: 0
  },
});

const times = ref({
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0
});

const timeCountdown = () => {
  let futureTenSecondTimestamp = props.expireTime;
  let futureMilliseconds = futureTenSecondTimestamp * 1000;
  let futureDate = new Date(futureMilliseconds);
  let now = new Date();
  let diffMilliseconds = futureDate - now;

  times.value.seconds = Math.floor((diffMilliseconds / 1000) % 60);
  times.value.minutes = Math.floor((diffMilliseconds / (1000 * 60)) % 60);
  times.value.hours = Math.floor((diffMilliseconds / (1000 * 60 * 60)) % 24);
  times.value.days = Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));
}

const startCountdown = () => {
  setInterval(() => {
    timeCountdown();
  }, 1000)
}

onMounted(() => {
  startCountdown();
})
</script>
<style scoped>
.timer >>> .van-cell:after {
  left: inherit;
  right: inherit;
  color: #fff;
}

.timer >>> .van-field__control {
  color: #fff;
}
</style>
<style lang="less" scoped>
.timer {
  padding: 20px;
  color: #fff;

  .presaleTimer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    user-select: none;
    color: gray;

    .timerItem {
      font-size: 20px;
      font-family: var(--head-font);
      font-weight: 800;
      letter-spacing: 2px;
      border: 2px solid rgb(30, 30, 30);
      border-left: 2px solid rgb(150, 150, 150);
      border-right: 2px solid rgb(150, 150, 150);
      padding: 5px 10px;
      border-radius: 15px;
      width: 100%;
      color: white;
      text-align: center;
    }

    .timerDot {
      font-size: 20px;
      font-family: var(--head-font);
      font-weight: 800;
    }

  }
}
</style>
