<script setup>
import PdfPreview from "@/components/PdfPreview.vue";
import {useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
const {t} = useI18n();
const router = useRouter();
const goBack = ()=>{
  router.back();
}
</script>

<template>
  <div>
    <div class="nav">
      <van-icon name="arrow-left" size="18" @click="goBack"/>
      <span>{{ t('trade.whitePage')}}</span>
      <span/>
    </div>
    <pdf-preview pdf-url="./white-page.pdf"/>
  </div>
</template>

<style scoped lang="less">
.nav {
  width: 100%;
  padding: 10px;
  background-color: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
}
</style>
