import {createStore} from "vuex";
import {tokenABI, contractABI, tokenAddress, contractAddress} from "@/assets/chain/contractDetails";

export default createStore({
    state: {
        web3: null,
        contractInstance: null,
        tokenInstance: null,
        // 添加一个状态来跟踪是否已初始化
        isInitialized: false,
    },
    mutations: {
        SET_WEB3(state, web3) {
            state.web3 = web3;
        },
        SET_CONTRACT_INSTANCE(state, contractInstance) {
            state.contractInstance = contractInstance;
        },
        SET_TOKEN_INSTANCE(state, tokenInstance) {
            state.tokenInstance = tokenInstance;
        },
        // 添加一个mutation来设置初始化状态
        SET_IS_INITIALIZED(state, value) {
            state.isInitialized = value;
        }
    },
    actions: {
        async initWeb3AndContract({commit, state}) {
            // 检查是否已经初始化，如果是，则直接返回
            if (state.isInitialized) {
                console.log('Web3 and contracts are already initialized.');
                return;
            }

            try {
                if (window.ethereum) {
                    const Web3 = require('web3');
                    const web3 = new Web3(window.ethereum);
                    await window.ethereum.enable();

                    const contractInstance = new web3.eth.Contract(contractABI, contractAddress);
                    const tokenInstance = new web3.eth.Contract(tokenABI, tokenAddress);

                    commit('SET_WEB3', web3);
                    commit('SET_CONTRACT_INSTANCE', contractInstance);
                    commit('SET_TOKEN_INSTANCE', tokenInstance);
                    // 设置初始化状态为true
                    commit('SET_IS_INITIALIZED', true);
                } else {
                    console.error("Please install MetaMask!");
                }
            } catch (error) {
                console.error("Error initializing Web3 and contract:", error);
            }
        }
    }
});
